// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/Header.tsx");
  import.meta.hot.lastModified = "1742928335441.917";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { SearchBar } from '~/components/header/SearchBar';
import { useRootLoader } from '~/utils/use-root-loader';
import { UserIcon } from '@heroicons/react/24/solid';
import { useScrollingUp } from '~/utils/use-scrolling-up';
export function Header({
  onCartIconClick,
  cartQuantity
}) {
  _s();
  const data = useRootLoader();
  const isSignedIn = !!data.activeCustomer.activeCustomer?.id;
  const isScrollingUp = useScrollingUp();
  return <div>
      <nav style={{
      width: '100%',
      backgroundColor: '#1A2634'
    }} className="bg-white border-gray-200 dark:border-gray-600">
        <div className="text-center text-sm py-2 px-2 xl:px-0" style={{
        width: '100%',
        height: '100%',
        background: '#1A2634'
      }}>
          <div className="max-w-screen-xl mx-2 py-1 md:mx-auto flex items-center justify-between">
            <div>
              <a href="/">
                <img src="/EuropalLogo.png" className="h-12 relative" alt="Europal Logo" />
              </a>
            </div>
            <div className="items-center">
              <SearchBar />
            </div>
            <div>
              {/* <input style={{ background: 'none', border: '1px solid #ccc', borderRadius: '30px', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', width: '70px', height: '35px' }} type="text" /> */}
              <div>
              <Link to={isSignedIn ? '/account' : '/sign-in'} className="flex space-x-1">
                <UserIcon className="w-4 h-4 text-white"></UserIcon>
                <span className='text-white'>{isSignedIn ? 'My Account' : 'Sign In'}</span>
              </Link>
            </div>
            </div>
          </div>
        </div>
        <header className="header">
            <div className="row v-center px-16">
              <div className="header-item item-center">
                <div className="menu-overlay">
                </div>
                <nav className="menu">
                  <div className="mobile-menu-head">
                    <div className="go-back"><i className="fa fa-angle-left"></i></div>
                    <div className="current-menu-title"></div>
                    <div className="mobile-menu-close">&times;</div>
                  </div>
                  <ul className="menu-main">
                    <li className="menu-item-has-children">
                      <a href="#" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    background: '#FFCC00',
                    borderRadius: 40,
                    paddingLeft: 15,
                    paddingRight: 15,
                    color: '#000'
                  }}>Shopping <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                      </svg>
                      </a>
                      <div className="sub-menu mega-menu mega-menu-column-4 mt-1 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600" style={{
                    width: '100%',
                    zIndex: 1
                  }}>
                        <div className="grid max-w-screen-xl px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-5 md:px-6">
                          {data.collections.map(collection => <div className="list-item">
                              <ul>
                                <li>
                                  <a href="#" className="block p-3 rounded-lg dark:hover:bg-gray-700">
                                    <div className="font-semibold mb-1">
                                      <Link to={'/collections/' + collection.slug} prefetch="intent" key={collection.id}>
                                        {collection.name}
                                      </Link>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                      {collection?.children?.map(res => {
                                  return <>
                                            <Link className="text-sm overflow-hidden text-gray-500 dark:text-gray-400 hover:text-lg transition-all duration-300 ease-in-out delay-100" key={res.id} to={'/collections/' + res.slug}>{res.name}</Link>
                                          </>;
                                })}
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>)}
                        </div>
                      </div>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#">Options <i className="fas fa-angle-down"></i></a>
                      <div className="sub-menu single-column-menu">
                        <ul>
                          <li><a href="#">Account settings</a></li>
                          <li><a href="#">Support</a></li>
                          <li><a href="#">License</a></li>
                          <li><a href="#">Sign out</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="#">Information</a>
                    </li>
                    <li>
                      <a href="#">Deals of the day !</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="flex">
                <div className="">
                  <button className="relative w-9 h-9 bg-white bg-opacity-20 rounded text-white p-1" onClick={onCartIconClick} aria-label="Open cart tray">
                    <ShoppingBagIcon></ShoppingBagIcon>
                    {cartQuantity ? <div style={{
                  background: '#FFCC00'
                }} className="absolute rounded-full -top-2 -right-2 w-6 h-6">
                        {cartQuantity}
                      </div> : ''}
                  </button>
                </div>
              </div>
            </div>
        </header>
      </nav>
    </div>;
}
_s(Header, "DYOMMUccHym665bWbVYRNKnzlic=", false, function () {
  return [useRootLoader, useScrollingUp];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;